import React from 'react'

const StarSolid = () => {
  return (
    <div>
      <i className="fa-solid fa-star"></i>
    </div>
  )
}

export default StarSolid
