import "./Components/All.css";
import AllRoutes from "./Components/AllRoutes";
import './Components/all.min.css'; 

function App() {
  return (
    <>
      <AllRoutes />
    </>
  );
}

export default App;
