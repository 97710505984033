import React from 'react'
import Nav from './Nav'

const About = () => {
  return (
    <div className='bg'>
      <Nav />
      <h1>About</h1>
    </div>
  )
}

export default About
