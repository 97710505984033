import React from 'react'

const StarRegular = () => {
  return (
    <div>
      <i className="fa-regular fa-star"></i>
    </div>
  )
}

export default StarRegular
